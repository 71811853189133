import {
  faFileArrowUp,
  type IconDefinition,
  faEllipsisVertical,
  faCircleInfo,
  faCircleNotch,
  faTimes,
  faCircle,
  faSave,
  faBold,
  faItalic,
  faFont,
  faMinus,
  faPlus,
  faRotateRight,
  faFileUpload,
  faPlug,
  faBolt
} from '@fortawesome/free-solid-svg-icons'

export default [
  faFileArrowUp,
  faEllipsisVertical,
  faCircleInfo,
  faCircleNotch,
  faTimes,
  faCircle,
  faSave,
  faBold,
  faItalic,
  faFont,
  faMinus,
  faPlus,
  faRotateRight,
  faFileUpload,
  faPlug,
  faBolt
] as IconDefinition[]
