<script setup lang="ts">
import type { Company, Tariff } from '@/interfaces/company'
import { computed } from 'vue'

const props = defineProps<{
  availableTariffs: Tariff[]
  peakConsumption: number
  standardConsumption: number
  offPeakConsumption: number
}>()

const tariffs = computed(() => props.availableTariffs)

const totalPerTariff = (tariff: Tariff) =>
  tariff.standard * props.standardConsumption +
  tariff.peak * props.peakConsumption +
  tariff.offPeak * props.offPeakConsumption

const round = (value: number) => Math.round(value * 1000) / 1000
type Times = 'peak' | 'standard' | 'offPeak'
const sortedTariffs = (type: Times) =>
  [...tariffs.value].sort((a, b) => (a[type] > b[type] ? 1 : -1))

const getLowestPriceTariff = (type: Times) => sortedTariffs(type).at(0)
const getHighestPriceTariff = (type: Times) => sortedTariffs(type).at(-1)

const totalTariffs = computed(() => tariffs.value.map(totalPerTariff))
const sortedTotal = computed(() =>
  [...totalTariffs.value].sort((a, b) => (a > b ? 1 : -1))
)
const lowestTotal = computed(() => sortedTotal.value.at(0))
const highestTotal = computed(() => sortedTotal.value.at(-1))
</script>

<template>
  <div class="gap-5 bg-sky-600 rounded-md flex flex-col justify-center">
    <table>
      <thead>
        <tr>
          <th class="w-10"></th>
          <th
            v-for="tariff in tariffs"
            :key="tariff.name"
            class="border-slate-200 border-solid border-b-2 p-3"
          >
            {{ tariff.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="text-right border-slate-200 border-solid border-r-2 p-3 whitespace-nowrap"
          >
            Ponta ({{ peakConsumption }} kWh)
          </td>
          <td class="text-center" v-for="tariff in tariffs" :key="tariff.name">
            <span
              class="m-3 py-3 px-5 rounded-lg"
              :class="[
                getLowestPriceTariff('peak')?.peak === tariff.peak &&
                  'bg-green-700',
                getHighestPriceTariff('peak')?.peak === tariff.peak &&
                  'bg-red-700'
              ]"
            >
              {{ round(tariff.peak * peakConsumption) }}€
            </span>
          </td>
        </tr>
        <tr>
          <td
            class="text-right border-slate-200 border-solid border-r-2 p-3 whitespace-nowrap"
          >
            Cheia ({{ standardConsumption }} kWh)
          </td>
          <td class="text-center" v-for="tariff in tariffs" :key="tariff.name">
            <span
              class="m-3 py-3 px-5 rounded-lg"
              :class="[
                getLowestPriceTariff('standard')?.standard ===
                  tariff.standard && 'bg-green-700',
                getHighestPriceTariff('standard')?.standard ===
                  tariff.standard && 'bg-red-700'
              ]"
            >
              {{ round(tariff.standard * peakConsumption) }}€
            </span>
          </td>
        </tr>
        <tr>
          <td
            class="text-right border-slate-200 border-solid border-r-2 p-3 whitespace-nowrap"
          >
            Vazia ({{ offPeakConsumption }} kWh)
          </td>
          <td class="text-center" v-for="tariff in tariffs" :key="tariff.name">
            <span
              class="m-3 py-3 px-5 rounded-lg"
              :class="[
                getLowestPriceTariff('offPeak')?.offPeak === tariff.offPeak &&
                  'bg-green-700',
                getHighestPriceTariff('offPeak')?.offPeak === tariff.offPeak &&
                  'bg-red-700'
              ]"
            >
              {{ round(tariff.offPeak * peakConsumption) }}€
            </span>
          </td>
        </tr>
        <tr>
          <td
            class="text-right border-slate-200 border-solid border-r-2 p-3 whitespace-nowrap"
          >
            Total ({{
              peakConsumption + standardConsumption + offPeakConsumption
            }}
            kWh)
          </td>
          <td class="text-center" v-for="tariff in tariffs" :key="tariff.name">
            <span
              class="m-3 py-3 px-5 rounded-lg"
              :class="[
                lowestTotal === totalPerTariff(tariff) && 'bg-green-700',
                highestTotal === totalPerTariff(tariff) && 'bg-red-700'
              ]"
            >
              {{ round(totalPerTariff(tariff)) }}€
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style></style>
