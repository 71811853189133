<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean
  icon?: string
  primary?: boolean
  danger?: boolean
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const toggleButton = () => {
  emit('update:modelValue', !props.modelValue)
}
</script>

<template>
  <button
    class="p-2 rounded text-white"
    :class="[
      primary && 'bg-sky-600 hover:bg-sky-700',
      primary && modelValue && 'bg-sky-800',
      danger && 'bg-rose-600 hover:bg-rose-700',
      danger && modelValue && 'bg-rose-800',
      !danger && !primary && 'bg-slate-600 hover:bg-slate-700',
      !danger && !primary && modelValue && 'bg-slate-800'
    ]"
    @click="toggleButton"
  >
    <fa-icon v-if="icon" :icon="icon" :class="$slots.default && 'mr-2'" />
    <slot />
  </button>
</template>

<style scoped></style>
