import type { Company } from '@/interfaces/company'

export const AVAILABLE_COMPANIES = [
  {
    name: 'Eda',
    availableTariffs: [
      {
        name: 'Simples',

        peak: 0.1608,
        standard: 0.1608,
        offPeak: 0.1608
      },
      {
        name: 'Bi-horária',
        peak: 0.1973,
        standard: 0.1973,
        offPeak: 0.1056
      },
      {
        name: 'Tri-horária',
        peak: 0.238,
        standard: 0.1711,
        offPeak: 0.1056
      }
    ]
  },
  {
    name: 'EDP',
    availableTariffs: [
      {
        power: 1.15,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.117,
            offPeak: 0.183,
            standard: 0.183,
            peak: 0.183
          }
        ]
      },
      {
        power: 2.3,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.1579,
            offPeak: 0.183,
            standard: 0.183,
            peak: 0.183
          }
        ]
      },
      {
        power: 3.45,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.2098,
            offPeak: 0.187,
            standard: 0.187,
            peak: 0.187
          },
          {
            name: 'Bi-Horário Semanal',
            powerPrice: 0.2235,
            offPeak: 0.1421,
            standard: 0.2264,
            peak: 0.2264
          },
          {
            name: 'Bi-Horário Diário',
            powerPrice: 0.2235,
            offPeak: 0.1421,
            standard: 0.2264,
            peak: 0.2264
          }
        ]
      },
      {
        power: 4.6,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.2637,
            offPeak: 0.1918,
            standard: 0.1918,
            peak: 0.1918
          },
          {
            name: 'Bi-Horário Semanal',
            powerPrice: 0.2747,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          },
          {
            name: 'Bi-Horário Diário',
            powerPrice: 0.2747,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          }
        ]
      },
      {
        power: 5.75,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.3073,
            offPeak: 0.1918,
            standard: 0.1918,
            peak: 0.1918
          },
          {
            name: 'Bi-Horário Semanal',
            powerPrice: 0.3191,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          },
          {
            name: 'Bi-Horário Diário',
            powerPrice: 0.3191,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          }
        ]
      },
      {
        power: 6.9,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.3544,
            offPeak: 0.1943,
            standard: 0.1943,
            peak: 0.1943
          },
          {
            name: 'Bi-Horário Semanal',
            powerPrice: 0.3636,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          },
          {
            name: 'Bi-Horário Diário',
            powerPrice: 0.3636,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          }
        ]
      },
      {
        power: 10.35,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.4917,
            offPeak: 0.1973,
            standard: 0.1973,
            peak: 0.1973
          },
          {
            name: 'Bi-Horário Semanal',
            powerPrice: 0.4962,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          },
          {
            name: 'Bi-Horário Diário',
            powerPrice: 0.4962,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          }
        ]
      },
      {
        power: 13.8,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.625,
            offPeak: 0.625,
            standard: 0.625,
            peak: 0.625
          },
          {
            name: 'Bi-Horário Semanal',
            powerPrice: 0.6297,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          },
          {
            name: 'Bi-Horário Diário',
            powerPrice: 0.6297,
            offPeak: 0.1437,
            standard: 0.2297,
            peak: 0.2297
          }
        ]
      },
      {
        power: 17.25,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.7632,
            offPeak: 0.2013,
            standard: 0.2013,
            peak: 0.2013
          },
          {
            name: 'Bi-Horário Semanal',
            powerPrice: 0.7734,
            offPeak: 0.1425,
            standard: 0.2338,
            peak: 0.2338
          },
          {
            name: 'Bi-Horário Diário',
            powerPrice: 0.7734,
            offPeak: 0.1425,
            standard: 0.2338,
            peak: 0.2338
          }
        ]
      },
      {
        power: 20.7,
        tariffs: [
          {
            name: 'Simples',
            powerPrice: 0.8973,
            offPeak: 0.2013,
            standard: 0.2013,
            peak: 0.2013
          },
          {
            name: 'Bi-Horário Semanal',
            powerPrice: 0.9088,
            offPeak: 0.1425,
            standard: 0.2338,
            peak: 0.2338
          },
          {
            name: 'Bi-Horário Diário',
            powerPrice: 0.9088,
            offPeak: 0.1425,
            standard: 0.2338,
            peak: 0.2338
          }
        ]
      },
      {
        power: 27.6,
        tariffs: [
          {
            name: 'Tri-Horário Semanal',
            powerPrice: 1.1734,
            offPeak: 0.1471,
            standard: 0.192,
            peak: 0.3191
          },
          {
            name: 'Tri-Horário Diário',
            powerPrice: 1.1734,
            offPeak: 0.1471,
            standard: 0.192,
            peak: 0.3191
          }
        ]
      },
      {
        power: 34.5,
        tariffs: [
          {
            name: 'Tri-Horário Semanal',
            powerPrice: 1.4472,
            offPeak: 0.1471,
            standard: 0.192,
            peak: 0.3191
          },
          {
            name: 'Tri-Horário Diário',
            powerPrice: 1.4472,
            offPeak: 0.1471,
            standard: 0.192,
            peak: 0.3191
          }
        ]
      },
      {
        power: 41.4,
        tariffs: [
          {
            name: 'Tri-Horário Semanal',
            powerPrice: 1.7224,
            offPeak: 0.1471,
            standard: 0.192,
            peak: 0.3191
          },
          {
            name: 'Tri-Horário Diário',
            powerPrice: 1.7224,
            offPeak: 0.1471,
            standard: 0.192,
            peak: 0.3191
          }
        ]
      }
    ]
  }
] satisfies Company[]
