<script setup lang="ts">
import { ref } from 'vue'
import TextField from './TextField.vue'
import { UUID } from '@/utils/utils'

defineProps<{
  value1: string | number
  value2: string | number
  value3: string | number
  icon?: string
  primary?: boolean
  danger?: boolean
  type: 'string' | 'number'
}>()

const id1 = ref(UUID())
const id2 = ref(UUID())
const id3 = ref(UUID())

defineEmits<{
  (e: 'update:value1', value: string | number): void
  (e: 'update:value2', value: string | number): void
  (e: 'update:value3', value: string | number): void
}>()
</script>

<template>
  <div class="gap-5 flex flex-row justify-center">
    <div class="flex flex-col w-1/3">
      <label :for="id1">
        <slot name="value1"></slot>
      </label>
      <text-field
        class="w-full"
        :id="id1"
        :type="type"
        :model-value="value1"
        @update:model-value="$emit('update:value1', $event)"
      ></text-field>
    </div>
    <div class="flex flex-col w-1/3">
      <label for="cheia"><slot name="value2"></slot></label>
      <text-field
        class="w-full"
        :id="id2"
        :type="type"
        :model-value="value2"
        @update:model-value="$emit('update:value2', $event)"
      ></text-field>
    </div>
    <div class="flex flex-col w-1/3">
      <label for="vazio"><slot name="value3"></slot></label>
      <text-field
        class="w-full"
        :id="id3"
        :type="type"
        :model-value="value3"
        @update:model-value="$emit('update:value3', $event)"
      ></text-field>
    </div>
  </div>
</template>

<style scoped></style>
