<script setup lang="ts">
import { ref } from 'vue'
import SimpleButton from './SimpleButton.vue'
defineProps<{
  modelValue: string
  options: string[]
  icon?: string
}>()
defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const opener = ref<typeof SimpleButton>()
const width = ref(0)
const updateWidth = () => {
  console.log(width, opener.value?.$el.offsetWidth)
  width.value = opener.value?.$el.offsetWidth
}
</script>

<template>
  <VDropdown>
    <simple-button
      ref="opener"
      class="w-full"
      :icon="icon"
      @click="updateWidth"
      >{{ modelValue }}</simple-button
    >
    <template #popper>
      <div
        class="flex flex-col max-h-40 rounded"
        :style="{ width: `${width}px` }"
      >
        <simple-button
          v-for="option in options"
          :key="option"
          v-close-popper
          class="rounded-none border-b border-black"
          @mousedown="$emit('update:modelValue', option)"
          >{{ option }}
        </simple-button>
      </div>
    </template>
  </VDropdown>
</template>

<style>
.v-popper__arrow-container {
  display: none;
}
</style>
