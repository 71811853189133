<script setup lang="ts">
const props = defineProps<{
  modelValue: string | number
  icon?: string
  primary?: boolean
  danger?: boolean
  type: 'string' | 'number'
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
}>()

const emitValue = (event: Event) => {
  if (props.type === 'string') {
    emit('update:modelValue', (event.target as HTMLInputElement).value)
  } else {
    emit('update:modelValue', (event.target as HTMLInputElement).valueAsNumber)
  }
}
</script>

<template>
  <input
    :type="type"
    :value="modelValue"
    class="p-2 rounded text-white text-center"
    :class="[
      primary && 'bg-sky-600 hover:bg-sky-700 active:bg-sky-800',
      danger && 'bg-rose-600 hover:bg-rose-700 active:bg-rose-800',
      !primary &&
        !danger &&
        'bg-slate-600 hover:bg-slate-700 active:bg-slate-800'
    ]"
    @input="emitValue"
  />
</template>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
