<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import TrioTextField from './components/common/TrioTextField.vue'
import ToggleButton from './components/common/ToggleButton.vue'
import SimpleButton from './components/common/SimpleButton.vue'
import DropDown from './components/common/DropDown.vue'
import ResultsPane from './components/ResultsPane.vue'
import { AVAILABLE_COMPANIES } from './models/values'
import { PDFHandler } from './models/pdf-handler'
import { pagesContentToPDFConsumption } from './models/handlers'
import {
  isComplexTariff,
  type ComplexTariff,
  type Tariff
} from './interfaces/company'

const peak = ref(0)
const standard = ref(0)
const offPeak = ref(0)

const peakPastMonth = ref(0)
const standardPastMonth = ref(0)
const offPeakPastMonth = ref(0)

const peakCurrentMonth = ref(0)
const standardCurrentMonth = ref(0)
const offPeakCurrentMonth = ref(0)

const differences = ref(true)

const currentPeak = computed(() =>
  differences.value ? peak.value : peakCurrentMonth.value - peakPastMonth.value
)
const currentStandard = computed(() =>
  differences.value
    ? standard.value
    : standardCurrentMonth.value - standardPastMonth.value
)
const currentOffPeak = computed(() =>
  differences.value
    ? offPeak.value
    : offPeakCurrentMonth.value - offPeakPastMonth.value
)
const availableSuppliers = ref(
  AVAILABLE_COMPANIES.map((company) => company.name)
)
const supplier = ref(availableSuppliers.value[0])
const company = computed(() =>
  AVAILABLE_COMPANIES.find((company) => company.name === supplier.value)
)
const hasComplexTariff = computed(() =>
  company.value?.availableTariffs[0]
    ? isComplexTariff(company.value.availableTariffs[0])
    : false
)

const selectedAvailableTariffs = computed(() => {
  return hasComplexTariff.value
    ? (
        company.value?.availableTariffs.find(
          (tariff) =>
            isComplexTariff(tariff) &&
            String(tariff.power + 'kWh') === power.value
        ) as ComplexTariff
      ).tariffs
    : (company.value?.availableTariffs as Tariff[])
})

async function updatePower() {
  await nextTick()
  if (!power.value || !availablePowers.value.includes(power.value))
    power.value = availablePowers.value[0]
}

const power = ref('')
const availablePowers = computed(
  () =>
    company.value?.availableTariffs.map((tariff) =>
      isComplexTariff(tariff) ? tariff.power + 'kWh' : ''
    ) ?? []
)
const showResults = ref(false)
const canShowResults = computed(
  () =>
    currentStandard.value &&
    currentOffPeak.value &&
    currentPeak.value &&
    company
)

const upload = ref<HTMLInputElement>()

async function fileChange(event: Event) {
  const files = (event.target as HTMLInputElement).files
  if (!files || !files.length) {
    return
  }
  const file = files[0]
  const pdfHandler = await PDFHandler.create()
  const pagesContent = await pdfHandler.getpagesContent(
    await file.arrayBuffer()
  )
  pagesContentToPDFConsumption(pagesContent)
}
</script>
<template>
  <main
    class="h-screen w-screen overflow-hidden bg-slate-200 flex flex-col gap-5 p-10 text-slate-200"
  >
    <!-- <input type="file" ref="upload" class="hidden" @change="fileChange" />
    <SimpleButton icon="file-upload" @click="upload?.click()"
      >Carregar uma fatura</SimpleButton
    > -->
    <div class="flex gap-5 max-lg:w-full h-fit">
      <div
        class="w-3/4 p-5 gap-5 bg-sky-600 rounded-md flex flex-col justify-center"
      >
        <trio-text-field
          v-if="differences"
          type="number"
          v-model:value1="peak"
          v-model:value2="standard"
          v-model:value3="offPeak"
        >
          <template #value1>Consumo na Hora de Ponta</template>
          <template #value2>Consumo na Hora de Cheia</template>
          <template #value3>Consumo na Hora de Vazio</template>
        </trio-text-field>
        <template v-else>
          <trio-text-field
            type="number"
            v-model:value1="peakPastMonth"
            v-model:value2="standardPastMonth"
            v-model:value3="offPeakPastMonth"
          >
            <template #value1>Hora de Ponta do Mes Passado</template>
            <template #value2>Hora de Cheia do Mes Passado</template>
            <template #value3>Hora de Vazio do Mes Passado</template>
          </trio-text-field>
          <trio-text-field
            type="number"
            v-model:value1="peakCurrentMonth"
            v-model:value2="standardCurrentMonth"
            v-model:value3="offPeakCurrentMonth"
          >
            <template #value1>Hora de Ponta do Mes Actual</template>
            <template #value2>Hora de Cheia do Mes Actual</template>
            <template #value3>Hora de Vazio do Mes Actual</template>
          </trio-text-field>
        </template>
        <toggle-button v-model="differences">{{
          differences
            ? `Mostrar caixas para os dois meses`
            : 'Mostrar caixas para diferença'
        }}</toggle-button>
      </div>
      <div class="w-1/4 p-5 gap-5 bg-sky-600 rounded-md flex flex-col">
        <div class="flex flex-col">
          <label for="supplier">Fornecedor de Eletricidade</label>
          <drop-down
            icon="plug"
            v-model="supplier"
            :options="availableSuppliers"
            class="w-full"
            @update:model-value="updatePower"
          />
          <template v-if="company && hasComplexTariff">
            <label for="power">Potência</label>
            <drop-down
              icon="bolt"
              v-model="power"
              :options="availablePowers"
              class="w-full"
            />
          </template>
        </div>
      </div>
    </div>
    <SimpleButton
      :disabled="!canShowResults"
      @click="showResults = !showResults"
      >Mostrar Resultados</SimpleButton
    >
    <results-pane
      v-if="showResults && selectedAvailableTariffs"
      :standard-consumption="currentStandard"
      :peak-consumption="currentPeak"
      :off-peak-consumption="currentOffPeak"
      :available-tariffs="selectedAvailableTariffs"
    ></results-pane>
  </main>
</template>

<style scoped></style>
