import type { TextContent } from 'pdfjs-dist/types/src/display/api'

export interface Tariff {
  name: string
  offPeak: number
  standard: number
  peak: number
}

export interface TariffWithPowerPrice extends Tariff {
  powerPrice: number
}

export interface ComplexTariff {
  power: number
  tariffs: TariffWithPowerPrice[]
}
export interface Company {
  name: string
  availableTariffs: (Tariff | ComplexTariff)[]
}

export function isSimpleTariff(
  tariff: Tariff | ComplexTariff
): tariff is Tariff {
  return typeof (tariff as Tariff).standard !== 'undefined'
}

export function isComplexTariff(
  tariff: Tariff | ComplexTariff
): tariff is ComplexTariff {
  return typeof (tariff as ComplexTariff).power !== 'undefined'
}

export type HandlerOutput = PDFConsumption | undefined
export type HandlerInput = (TextContent | string)[]
export interface PDFConsumption {
  name: string
  offPeakConsumption: number
  standardConsumption: number
  peakConsumption: number
}
export type Handler = (input: HandlerInput) => HandlerOutput
